import { ClassNames } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from "react-router-dom";

const GuestGuard = ({ children, authenticated }) => {
    const history = useHistory();

    console.log('guestguard');
    
    if (typeof authenticated !== 'undefined' && authenticated !== null && authenticated) {
        history.push("/user/profile");
    }

    return children;
}

export default GuestGuard; 