import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import DrawerHeader from './DrawerHeader';
import Navigation from './Navigation';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        border: 'none',
        ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {...openedMixin(theme),border: 'none', boxShadow: 'inset -10px 0px 20px rgb(0 0 0 / 10%)'},
        }),
        ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {...closedMixin(theme),border: 'none', boxShadow: 'inset -10px 0px 20px rgb(0 0 0 / 10%)'},
        }),
    }),
);

export default function Drawer({ open }) {
    return (
        <StyledDrawer variant="permanent" open={open}>
            <DrawerHeader />
            <Navigation />
        </StyledDrawer>
)
}