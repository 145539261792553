import { createContext, useContext } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { useState } from "react";

const ConfirmationContext = createContext({});

export const ConfirmationProvider = ({ children }) => {
    const [config, setConfig] = useState({});
    const [open, setOpen] = useState(false);

    const resetDialog = () => {
        setOpen(false);
        setConfig({});
    }

    const onConfirm = () => {
        resetDialog();
        config.actionCallback(true);
    };

    const onDismiss = () => {
        resetDialog();
        config.actionCallback(false);
    };

    const openConfirmation= ({ title, message, actionCallback }) => {
        setConfig({ title, message, actionCallback });
        setOpen(true);
    }

    return (
        <>
            <ConfirmationContext.Provider value={{ openConfirmation }}>
                <ConfirmationDialog 
                    open={open}
                    config={config}
                    onConfirm={onConfirm}
                    onDismiss={onDismiss}
                />
                {children}
            </ConfirmationContext.Provider>
        </>
    );
};

export const useConfirmation = () => {
    const { openConfirmation } = useContext(ConfirmationContext);

    const getConfirmation = ({...options}) => (
        new Promise((res) => {
            openConfirmation({ actionCallback: res, ...options })
        })
    );

    return { getConfirmation };
}