import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import MuiAppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as MuleIcon } from '../../images/logo/GlowBlur.svg';
import avatar from '../../images/avatar/user.jpg';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InputBase from '@mui/material/InputBase';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useSanctum } from "react-sanctum";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
padding: '5px 0px',
zIndex: theme.zIndex.drawer + 1,
transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    }),
}),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => {
return {
    transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    }),
}
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'none',
    textAlign: 'left',
    transition: theme.transitions.create('color'),
    color: alpha(theme.palette.common.white, 0.50),
    '&:hover': {
      color: alpha(theme.palette.common.white, 1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 100
    },
  }));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    textAlign: 'left',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%'
    },
}));
  
const HeaderLogo = styled(MuleIcon)({
    height: '2.5em',
    width: 'auto',
    marginRight: '0.7em'
});

export default function Header({ open, handleDrawerOpen, handleDrawerClose , ...rest}) {
    const history = useHistory();
    const { authenticated, user, signOut } = useSanctum();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [searchString, setSearchString] = useState('');
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isMenuOpen = Boolean(anchorEl);

    const handleSignOut = () => {
        signOut()
        .then(history.push("/"));
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
  
    const handleSearch = (event) => {
        setSearchString(event.target.value);
    }
  
    const handleSearchBlur = (event) => {
        setSearchString('');
    }
      
    const handleSearchExit = (event) => {
        if(event.key === "Escape") {
          setSearchString('');
        }
    }

    const menuId = 'primary-search-account-menu';

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose} component={RouterLink} to="profile"><AccountCircle style={{ marginRight: '0.5em' }}/>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}><ExitToAppIcon style={{ marginRight: '0.5em' }}/>Sign Out</MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        id={mobileMenuId}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
        <>
            <StyledAppBar position="fixed" open={open} style={ searchString.length !== 0 ? { height: '100vh' } : {} }>
                <Toolbar style={{ paddingLeft: '28px'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                    sx={{
                    marginRight: '36px',
                    ...(!open && { display: 'none' }),
                    }}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <HeaderLogo/>
                <Typography variant="body2" noWrap component="h1" style={{ fontWeight: 600,  letterSpacing: '0.1em', marginRight: '1em', textTransform: 'uppercase' }}>
                    Green Mule <Typography variant="caption">Beta</Typography>
                </Typography>
                <Divider orientation="vertical" flexItem />
                {/* <Search>
                    <SearchIconWrapper>
                    <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                    value={searchString}
                    onChange={handleSearch}
                    onBlur={handleSearchBlur}
                    onKeyDown={handleSearchExit}
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    />
                </Search> */}
                <Box sx={{ flexGrow: 1 }} />
                <Divider orientation="vertical" flexItem />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                    </IconButton> */}
                    {/* <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    style={{ marginLeft: '20px', padding: '0px 17px'}}
                    >
                    <Badge badgeContent={17} color="primary">
                        <NotificationsIcon />
                    </Badge> 
                    </IconButton>*/}
                    <Button
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    style={{ marginLeft: '1em' }}
                    >
                    {/* <Avatar alt="Vyvey" src={avatar} style={{ marginRight: '0.5em' }} /> */}
                    <Typography variant="body2" noWrap style={{ fontWeight: 600,  letterSpacing: '0.1em'}}>
                       {`${user?.first_name} ${user?.last_name}`} <Typography variant="caption">{user?.type}</Typography>
                    </Typography>
                    <ArrowDropDownIcon style={{ color: 'grey' }}/>
                    </Button>
                    
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                    >
                    <MoreIcon />
                    </IconButton>
                </Box>
                </Toolbar>
                {
                    searchString && (
                    <Grid container spacing={1} mt={2}>
                            <Grid item xs={3}>
                                <Typography variant="h5">Customers</Typography>
                                    <Menu>
                                    <MenuItem>
                                        fazfafafaafffdgqgdqg
                                    </MenuItem>
                                    <MenuItem>
                                        Test
                                    </MenuItem>
                                    <MenuItem>
                                        Test
                                    </MenuItem>
                                    </Menu>
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="h5">Services</Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="h5">Transactions</Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="h5">Settings</Typography>
                            </Grid>
                    </Grid>
                    )  
                }
            </StyledAppBar >
            {renderMobileMenu}
            {renderMenu}
        </>
    );
}