import { styled } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import { Slide, Button } from '@mui/material';

const StyledSnackbar = styled((props) => (
  <SnackbarProvider 
    {...props}
  />
))(({ theme }) => ({
  '&&.SnackbarItem-variantSuccess': {
    background: 'linear-gradient(45deg, #11998e, #38ef7d)',
  },
  '&&.SnackbarItem-variantWarning': {
    background: 'linear-gradient(45deg, #ec6f66, #f3a183)',
  },
  '&&.SnackbarItem-variantInfo': {
    background: 'linear-gradient(45deg, #514a9d, #24c6dc)',
  },
  '&&.SnackbarItem-variantError': {
    background: 'linear-gradient(45deg, #e43a15, #e65245)',
  }
}));

function CustomSnackBarProvider({ children, ...rest}){

    return (
        <StyledSnackbar 
          {...rest}
          preventDuplicate
          autoHideDuration={3000}
          maxSnack={4}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          TransitionComponent={Slide}
        >
        { children }
        </StyledSnackbar>
    );
}

export default CustomSnackBarProvider;