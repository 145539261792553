import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Header from './Header';
import Breadcrumbs from '../../components/BreadCrumbs';
import Drawer from './Drawer';
import DrawerHeader from './DrawerHeader';


export default function UserLayout (props) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return(
        <Container maxWidth="l"> 
        <Box sx={{ display: 'flex' }}>
            <Header open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
            <Drawer open={open}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              <Breadcrumbs baseRoute='/user'/>
              {props.children}
            </Box>
        </Box>
        </Container>
    )
}