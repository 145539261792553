import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const ConfirmationDialog = ({ onConfirm, onDismiss, open, config }) => {
    return (
        <Dialog
            open={open}
            onClose={() => onDismiss()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            { config.title }
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            { config.message }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => onDismiss()} variant="outlined" color="success">Cancel</Button>
            <Button onClick={() => onConfirm()} variant="outlined" color="error" autoFocus>
                Continue
            </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmationDialog;