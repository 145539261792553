import {ErrorBoundary} from 'react-error-boundary';

export default function CustomErrorBoundary({ children, ...rest}) {

    const formatMessage = ({error, resetErrorBoundary, ...rest}) => {
        console.log(error,resetErrorBoundary,rest);
        return (
            <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
            </div>
        );
    }

    return (
        <ErrorBoundary
            FallbackComponent={formatMessage}
            onReset={() => {
            // reset the state of your app so the error doesn't happen again
            }}
        >
            { children }
        </ErrorBoundary>
    );
}