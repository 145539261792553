import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { styled, emphasize } from '@mui/material/styles';

import { Route, Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
        cursor: 'pointer',
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

export default function Breadcrumbs({ baseRoute, ...rest}) {

    const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

    return (
        <Route>
            {({ location }) => {
                const pathnames = location.pathname.replace(baseRoute,'').split('/').filter((x) => x);
                
                return pathnames.length === 0 ? null : (
                <MuiBreadcrumbs aria-label="breadcrumb" mb={2} separator="›">
                    <StyledBreadcrumb
                        component={LinkRouter}
                        to={baseRoute || '/'}
                        label="Home"
                        icon={<HomeIcon fontSize="small" />}
                    />
                    {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const label = pathnames.slice(0, index + 1)[index];
                        const to = `${baseRoute}/${pathnames.slice(0, index + 1).join('/')}`;
                        return (
                        <StyledBreadcrumb
                            component={LinkRouter}
                            to={to}
                            label={_.capitalize(label)}
                            key={index}
                        />
                        );

                    })}
                </MuiBreadcrumbs>
                );
            }}
        </Route>
    );
}