import { styled } from '@mui/material/styles';
import { List, ListItemIcon, ListItemText, ListItem, Divider, Badge } from "@mui/material";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoneyIcon from '@mui/icons-material/Money';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useSanctum } from 'react-sanctum';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '22px',
    '&&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark
    }}
));

export default function Navigation() {
    const location = useLocation();
    const { user } = useSanctum();

    const menuItems = [
      // {
      //   name: 'Dashboard',
      //   route: '/',
      //   icon: <DashboardIcon/>
      // },
      {
        name: 'Export',
        route: '/exports',
        icon: <ScheduleSendIcon/>
      },
      {
        name: 'Import',
        route: '/imports',
        icon: <CloudDownloadIcon/>
      },
      {
        name: 'Bank Import',
        route: '/bankimport',
        icon: <AccountBalanceIcon />
      },
      {
        name: 'Health',
        route: '/health',
        icon: user?.blocked_services > 0 ? (
          <Badge badgeContent={'!'} color="error">
            <HealthAndSafetyIcon/>
          </Badge>
          ) : <HealthAndSafetyIcon/>,
        divider: 1 
      },
      {
        name: 'Balance Configuration',
        route: '/ibanconfig',
        icon: <AccountBalanceIcon />
      },
      {
        name: 'VAT Configuration',
        route: '/vatconfig',
        icon: <MoneyIcon />
      },
      {
        name: 'Service Configuration',
        route: '/serviceconfig',
        icon: <SettingsApplicationsIcon/>
      },
      {
        name: 'Profile',
        route: '/profile',
        icon: <AccountCircleIcon/>
      }
      // {
      //   name: 'Shops',
      //   route: '/shops',
      //   icon: <StorefrontIcon />
      // },
      // {
      //   name: 'Settings',
      //   route: '/profile',
      //   icon: <SettingsApplicationsIcon/>
      // }
  ];

    return (
        <List>
            {menuItems.map((text, index) => (
              <>
                <StyledListItem key={text.route} selected={`/user${text.route}` === location.pathname.split('/').splice(0,3).join('/')} button key={text.name} component={RouterLink} to={`/user${text.route}`}>
                <ListItemIcon>
                    {text.icon}
                </ListItemIcon>
                        <ListItemText primary={text.name} />
                        <Divider/>
                </StyledListItem>
                { text.divider ? <Divider/> : null }
              </>
            ))}
            
        </List> 
);
}