import { ClassNames } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from "react-router-dom";

const UserGuard = ({ children, authenticated }) => {
    const history = useHistory();
    
    if (typeof authenticated !== 'undefined' && authenticated !== null && !authenticated) {
        // console.log('checking',authenticated);
        history.push("/");
    }

    return authenticated && (authenticated === true ? children : null);
}

export default UserGuard; 