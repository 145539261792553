import { createTheme } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';

export default function createCustomTheme() {

    let theme = createTheme({
        typography: {
          fontFamily: [
            'Arimo',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
          ].join(','),
        },
        palette: {
          tonalOffset: 0.05,
          type: 'dark',
          mode: 'dark',
          background: {
            paper: 'rgba(22,25,39,0.9)',
            lightRow: 'rgba(33, 36, 48, 0.9)',
            darkRow: 'rgba(22,25,39,0.9)'
          },
          primary: {
            main: '#96e6a1',
          },
          secondary: {
            main: 'rgb(50,227,224)',
          },
          success: {
            main: '#11998e'
          },
          info: {
            main: 'rgb(50,227,224)',
          }
        },
      });
    
    theme = createTheme(theme,{
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: theme.palette.mode === 'dark' ? darkScrollbar() : null,
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              // borderRadius: '10px',
              backgroundColor: 'rgba(22,25,39,0.9)',
              backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }
          }
        },
      },
    });

    return theme;
} 

