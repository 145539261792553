import { styled } from '@mui/material/styles';
import { List, ListItemIcon, ListItemText, ListItem } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const menuItems = [
    {
      name: 'Dashboard',
      route: '/',
      icon: <DashboardIcon/>
    },
    {
      name: 'Users',
      route: '/users',
      icon: <PeopleIcon/>
    },
    {
      name: 'Shops',
      route: '/shops',
      icon: <ShoppingCartIcon/>
    },
    {
      name: 'Services',
      route: '/services',
      icon: <ElectricalServicesIcon />
    },

    {
      name: 'Balances',
      route: '/balances',
      icon: <MoreIcon/>
    },
    {
      name: 'Settings',
      route: '/configuration',
      icon: <SettingsApplicationsIcon/>
    }
];

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingLeft: '22px',
    '&&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark
    }}
));

export default function Navigation() {
    const location = useLocation();
    return (
        <List>
            {menuItems.map((text, index) => (
                <StyledListItem selected={`/admin${text.route}` === location.pathname.split('/').splice(0,3).join('/')} button key={text.name} component={RouterLink} to={`/admin${text.route}`}>
                <ListItemIcon>
                    {text.icon}
                </ListItemIcon>
                        <ListItemText primary={text.name} />
                </StyledListItem>
            ))}
        </List> 
);
}