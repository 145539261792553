import { lazy } from "react";

import {
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

import UserGuard from "./components/UserGuard";
import GuestGuard from "./components/GuestGuard";

import { useSanctum } from "react-sanctum";

import AdminLayout from './layouts/admin';
import UserLayout from './layouts/user';
import GuestLayout from './layouts/guest';

import { Suspense } from "react";

import LoadingScreen from './components/LoadingScreen';
import CustomErrorBoundary from "./components/CustomErrorBoundary";

const routesConfig = [
    {
        exact: true,
        path: '/',
        layout: GuestLayout,
        guard: GuestGuard,
        component: lazy(() => import('./views/auth')),
    },
    {
        exact: true,
        path: '/login',
        layout: GuestLayout,
        guard: GuestGuard,
        component: lazy(() => import('./views/auth')),
    },
    {
        exact: true,
        path: '/admin',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/transactions/TransactionsOverview')),
    },
    {
        exact: true,
        path: '/admin/users',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/users/')),
    },
    {
        exact: true,
        path: '/admin/users/create',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/users/UserCreate')),
    },
    {
        exact: true,
        path: '/admin/users/:id',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/users/UserDetail')),
    },
    {
        exact: true,
        path: '/balances',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/transactions/TransactionsOverview2')),
    },
    {
        exact: true,
        path: '/admin/configuration',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/admin/config')),
    },

    // User Routes
    // {
    //     exact: true,
    //     path: '/user',
    //     component: lazy(() => import('./views/user/dashboard')),
    // },
    {
        exact: true,
        path: '/user/exports/:id',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/exports/detail')),
    },
    {
        exact: true,
        path: '/user/exports',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/exports')),
    },
    {
        exact: true,
        path: '/user/health',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/health')),
    },
    // {
    //     exact: true,
    //     path: '/user/export/:id',
    //     component: lazy(() => import('./views/user/exportDetail')),
    // },
    // {
    //     exact: true,
    //     path: '/user/balances',
    //     component: lazy(() => import('./views/user/items')),
    // },
    {
        exact: true,
        path: '/user/serviceconfig',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/services')),
    },
    {
        exact: true,
        path: '/user/vatconfig',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/items')),
    },
    {
        exact: true,
        path: '/user/profile',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/profile')),
    },
    {
        exact: true,
        path: '/user/ibanconfig',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/ibans')),
    },
    {
        exact: true,
        path: '/user/bankimport',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/bankImport')),
    },
    {
        exact: true,
        path: '/user/bankimport/verification/:id',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/bankImport/verification')),
    },
    {
        exact: true,
        path: '/user/imports',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/imports')),
    },
    {
        exact: true,
        path: '/user/imports/:id',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/imports/detail')),
    },
    {
        exact: true,
        path: '/user/bankimport/:id',
        layout: UserLayout,
        guard: UserGuard,
        component: lazy(() => import('./views/user/bankImport/detail')),
    },
    // {
    //     exact: true,
    //     path: '/user/items/:id',
    //     component: lazy(() => import('./views/user/itemDetail')),
    // },
]

export default function Routes() {
    const { authenticated, user } = useSanctum();
    console.log('user', user);
    // const routes = (
    //     <UserLayout>
    //         <Suspense fallback={<LoadingScreen />}>
    //             <CustomErrorBoundary>
    //                 <Switch>
    //                     {
    //                         routesConfig.map((route,i) => {
    //                             const Component = route.component;
    //                             return (
    //                                 <Route
    //                                     exact={route.exact} 
    //                                     path={route.path} 
    //                                     key={i} 
    //                                     render={(props) => (<Component {...props} />)}
    //                                 />
    //                             );
    //                         })
    //                     }
    //                 </Switch>
    //             </CustomErrorBoundary>
    //         </Suspense>
    //     </UserLayout>
    // );

    const routes = 
            routesConfig.map((route,i) => {
                const Component = route.component;
                const Guard = route.guard;
                const Layout = route.layout;

                if(Guard) {
                    return (
                        <Guard
                            key={i} 
                            authenticated={authenticated}
                        >
                            <Route
                                exact={route.exact} 
                                path={route.path} 
                            >
                                <Layout>
                                    <Suspense fallback={<LoadingScreen />}>
                                        <CustomErrorBoundary>
                                         <Component/>
                                        </CustomErrorBoundary>
                                    </Suspense>
                                </Layout>
                            </Route>
                        </Guard>
                    );
                } else {
                    return (
                        <Route
                            exact={route.exact} 
                            path={route.path} 
                            key={i} 
                        >
                            <Layout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <CustomErrorBoundary>
                                        <Component/>
                                    </CustomErrorBoundary>
                                </Suspense>
                            </Layout>
                        </Route>
                    );
                }
            })

    // routes.push(<Redirect to='/' />);

    return routes;
}