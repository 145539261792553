import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function GuestLayout (props) {
    return(
        <Container maxWidth="l"> 
        <Box sx={{ display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh'
        }}>
              {props.children}
        </Box>
        </Container>
    )
}