import './App.css';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import createCustomTheme from './themes';
import CustomSnackBarProvider from './components/CustomSnackBarProvider/';
import Routes from './Routes';
import { Button } from '@mui/material';

import { ConfirmationProvider } from './hooks/ConfirmationProvider';

import { Sanctum } from "react-sanctum";

const history = createBrowserHistory();

// const sanctumConfig = {
//   apiUrl: "http://localhost:8000",
//   csrfCookieRoute: "sanctum/csrf-cookie",
//   signInRoute: "api/login",
//   signOutRoute: "api/logout",
//   userObjectRoute: "api/user",
// };

const sanctumConfig = {
  apiUrl: "https://api.greenmule.be",
  csrfCookieRoute: "sanctum/csrf-cookie",
  signInRoute: "api/login",
  signOutRoute: "api/logout",
  userObjectRoute: "api/user",
};

function App() {

  return (
      
      <ThemeProvider theme={createCustomTheme()}>
        <CssBaseline />
        <CustomSnackBarProvider>
          <ConfirmationProvider>
            <Sanctum config={sanctumConfig}>
              <div className="App">
                <Router history={history}>
                  <Routes/>
                </Router>
              </div>
            </Sanctum>
          </ConfirmationProvider>
        </CustomSnackBarProvider>
      </ThemeProvider>
    
  );
}

export default App;
